<template>
    <v-card
        elevation="0"
        color="#fff"
        class="mt-8 hover-v-card"
    >
        <v-row>
            <v-col cols="4" lg="4" md="5" class="d-flex align-center">
            <v-img
                lazy-src=""
                max-height="150"
                max-width="125"
                src="@/assets/img/log-gob.png"
                class="mx-auto"
            ></v-img>
            </v-col>
            <v-col cols="8" lg="8" md="7">
                <span>{{ convocatoria.encabezado }}</span>

                <p class="mt-4 mb-0">
                    <span class="font-weight-bold">Fecha de recepción:</span>
                    {{ moment(convocatoria.fecha_recepcion).format("DD/MM/YYYY") }}
                </p>

                <p class="mt-4 mb-0">
                    <span class="font-weight-bold">Fecha de evaluación:</span>
                    {{ moment(convocatoria.fecha_evaluacion).format("DD/MM/YYYY") }}
                </p>

                <p class="mt-4 mb-0">
                    <span class="font-weight-bold">Fecha de resultados:</span>
                    {{ moment(convocatoria.fecha_resultados).format("DD/MM/YYYY") }}
                </p>

                <div class="mt-4 d-flex align-center" style="gap: 8px;">
                    <v-chip label>
                        <v-icon class="mr-1" style="font-size: 22px !important;">{{ iconoEstado }}</v-icon>
                        {{ convocatoria.estado.nombre }}
                    </v-chip>

                    <!-- Participar -->
                    <v-tooltip :key="10" v-if="mostrarParticipar & convocatoria.estado.codigo === 'RECEPCION'" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-bind="attrs"
                                v-on="on"
                                style="order: 10" 
                                color="primary" 
                                icon
                                @click.stop="abrirConfirmacionParticipacion" 
                            >
                                <v-icon>mdi-account-check</v-icon>
                            </v-btn>
                        </template>
                        <span>Participar</span>
                    </v-tooltip>

                    <!-- Ver documento -->
                    <v-tooltip :key="20" v-if="convocatoria.adjunto" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-bind="attrs" 
                                v-on="on"
                                style="order: 20" 
                                color="primary" 
                                icon
                                @click.stop="visualizarAdjuntoConvocatoria" 
                            >
                                <v-icon>mdi-file-pdf-box</v-icon>
                            </v-btn>
                        </template>
                        <span>Ver documento convocatoria</span>
                    </v-tooltip>

                    <!-- Acciones oferta -->
                    <template v-if="mostrarBotonAdjuntos">
                        <v-tooltip :key="30" v-if="convocatoria.estado.codigo === 'RECEPCION'" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn 
                                    v-bind="attrs" 
                                    v-on="on"
                                    style="order: 30" 
                                    color="primary" 
                                    icon
                                    @click.stop="abrirModalEdicionAdjunto" 
                                >
                                    <v-icon>mdi-offer</v-icon>
                                </v-btn>
                            </template>
                            <span>Subir oferta</span>
                        </v-tooltip>
                        <v-tooltip :key="40" v-if="convocatoria.participacion?.adjunto" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs" 
                                    v-on="on"
                                    style="order: 40"
                                    color="primary"
                                    icon
                                    @click.stop="visualizarAdjuntoParticipacion"
                                >
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <span>Ver oferta</span>
                        </v-tooltip>

                        <v-tooltip :key="50" v-if="convocatoria.participacion && convocatoria.participacion.id_estado_participacion_prov !== 2" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn 
                                    v-bind="attrs" 
                                    v-on="on" 
                                    style="order: 50" 
                                    color="primary" 
                                    icon
                                    @click.stop="$router.push(`/cm-documentos-convocatoria/${convocatoria.id}`)" 
                                >
                                    <v-icon>mdi-archive</v-icon>
                                </v-btn>
                            </template>
                            <span class="d-inline-block text-center">Ver documentos de la convocatoria</span>
                        </v-tooltip>
                    </template>
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
export default {
    name: 'CardConvocatoria',
    emits: ['on-visualizar-adjunto', 'on-confirmar-participacion', 'on-subir-oferta', 'on-visualizar-adjunto-participacion'],
    props: {
        convocatoria: { type: Object },
    },
    computed: {
        iconoEstado() {
            const estado = this.convocatoria.estado.codigo;
            switch (estado) {
                case 'RECEPCION':
                    return 'mdi-email-arrow-left';
                case 'EVALUACION':
                    return 'mdi-progress-pencil';
                case 'RESULTADOS':
                    return 'mdi-order-bool-descending-variant';
                default: 
                    return 'mdi-timer-off';
            };
        },
        mostrarParticipar() {
            const { participacion } = this.convocatoria;
            return !participacion || participacion.id_estado_participacion_prov === 2;
        },
        mostrarBotonAdjuntos() {
            const { participacion } = this.convocatoria;
            return participacion && participacion.id_estado_participacion_prov === 1;
        },
    },
    methods: {
        visualizarAdjuntoConvocatoria() {
            this.$emit('on-visualizar-adjunto', this.convocatoria);
        },
        // Participar
        abrirConfirmacionParticipacion() {
            this.$emit('on-confirmar-participacion', this.convocatoria);
        },
        // Ofertas
        abrirModalEdicionAdjunto() {
            this.$emit('on-subir-oferta', this.convocatoria);
        },
        visualizarAdjuntoParticipacion() {
            this.$emit('on-visualizar-adjunto-participacion', this.convocatoria);
        },
    },
}
</script>