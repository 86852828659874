<template>
  <v-card
    elevation="0"
    color="#fff"
    class="mt-8 hover-v-card"
    @click="irAlProceso"
  >
    <v-row>
      <v-col cols="4" lg="4" md="5" class="d-flex align-center">
        <v-img
            lazy-src=""
            max-height="150"
            max-width="125"
            src="@/assets/img/log-gob.png"
            class="mx-auto"
        ></v-img>
      </v-col>
      <v-col cols="8" lg="8" md="7">
        <span>
          {{ proceso.nombre_proceso }}
        </span>
        <p>{{ proceso.institucion }}</p>
        <p v-if="proceso.fecha_publicacion">
          Fecha de publicación:
          {{ moment(proceso.fecha_publicacion).format("DD/MM/YYYY") }}
        </p>
        <p>
          Período de inscripción:
          {{ `${moment(proceso.fecha_inicio_inscripcion).format("DD/MM/YYYY")} - ${moment(proceso.fecha_fin_inscripcion).format("DD/MM/YYYY")}` }}
        </p>
        <p>Estado: {{ [1].includes(proceso.id_estado_proceso) ? proceso.estado_actual : proceso.estado_proceso }}</p>
        <p>Código: {{ proceso.codigo_proceso }}</p>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
    name: 'CardProcesoProveedor',
    props: {
        proceso: { type: Object },
    },
    methods: {
        irAlProceso() {
            if (this.proceso.version === 2) {
                this.$router.push(`/pac-procesos/etapas/${this.proceso.id}`);
            } else if (this.proceso.version === 1) {
                this.$router.push(`/pac-procesos-v1/etapas/${this.proceso.id}`);
            }
        },
    },
}
</script>
