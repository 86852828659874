<template>
  <Fragment>
    <section v-if="perfilCompleto === true && !this.tieneSancion">
      <v-col cols="12" md="12">
        <p class="text-h6 font-museo-sans mb-0 secondary--text">
          Procesos de compra
        </p>
      </v-col>
      <v-row class="mx-4 mt-4">
        <v-col cols="12" sm="12" md="4">
          <v-text-field
              outlined
              dense
              label="Buscar proceso por código/nombre"
              v-model="filtros.busqueda"
              @keyup="filterName"
              clearable
              :loading="loading"
              @click:clear="limpiarFiltros"
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-autocomplete
              outlined
              dense
              label="Buscar proceso por institución"
              :items="ctl_institucion_var"
              item-text="nombre"
              item-value="id"
              v-model="filtros.id_institucion"
              :disabled="modalidadEscmConvocatorias"
              @change="getPaacProveedor(filtros)"
              clearable
              :loading="loading"
          />
        </v-col>
      </v-row>
  
      <v-row class="mx-6 my-6">
        <v-col cols="12" sm="12" md="3" lg="3">
          <div class="">
            <v-list dense color="bgMinsal">
              <v-list-item-group color="secondary">
                <h3>Participación</h3>
                <v-list-item>
                  <template v-slot:default="{ active }">
                    <v-btn
                        icon
                        v-if="active"
                        @click="
                        active
                          ? deleteFiltro('participacion')
                          : aplicarFiltro(true, 'participacion')
                      "
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-list-item-content
                        @click="
                        active
                          ? deleteFiltro('participacion')
                          : aplicarFiltro(true, 'participacion')
                      "
                    >
                      <v-list-item-title class="filters-text"
                      >Participando</v-list-item-title
                      >
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <v-list-item>
                  <template v-slot:default="{ active }">
                    <v-btn
                        icon
                        v-if="active"
                        @click="
                        active
                          ? deleteFiltro('participacion')
                          : aplicarFiltro(false, 'participacion')
                      "
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-list-item-content
                        @click="
                        active
                          ? deleteFiltro('participacion')
                          : aplicarFiltro(false, 'participacion')
                      "
                    >
                      <v-list-item-title class="filters-text"
                      >Sin participar</v-list-item-title
                      >
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-divider></v-divider>
  
            <v-list dense color="bgMinsal">
              <v-list-item-group color="secondary">
                <h3>Instituciones</h3>
                <v-list-item v-for="item in tipoEntidades" :key="item.id" :disabled="modalidadEscmConvocatorias">
                  <template v-slot:default="{ active }">
                    <v-btn
                      v-if="active"
                      icon
                      :disabled="modalidadEscmConvocatorias"
                      @click="active? deleteFiltro('entidad') : aplicarFiltro(item.id, 'entidad')"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-list-item-content @click="active ? deleteFiltro('entidad') : aplicarFiltro(item.id, 'entidad')">
                      <v-list-item-title class="filters-text" :style="`color: ${ modalidadEscmConvocatorias ?  'var(--v-text-lighten3) !important;' : '#424c7b'}`">
                        {{ item.nombre }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
  
              <v-divider></v-divider>
              <br />
              <v-list-item-group color="secondary">
                <h3>Tipo de contrato</h3>
                <v-list-item v-for="item in listaTipoContratos" :key="item.id">
                  <template v-slot:default="{ active }">
                    <v-btn
                        icon
                        v-if="active"
                        @click="
                        active
                          ? deleteFiltro('contrato')
                          : aplicarFiltro(item.id, 'contrato')
                      "
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-list-item-content
                        @click="
                        active
                          ? deleteFiltro('contrato')
                          : aplicarFiltro(item.id, 'contrato')
                      "
                    >
                      <v-list-item-title class="filters-text">{{
                          item.nombre
                        }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="9" lg="9">
          <v-subheader v-if="convocatorias.isLoading">
            Cargando lista de convocatorias...
          </v-subheader>
  
          <v-subheader v-if="cargandoProcesos">
            Cargando lista de procesos...
          </v-subheader>
  
          <template v-if="cantidadProcesos === 0">
            <v-subheader v-if="!convocatorias.isLoading && !cargandoProcesos">
              {{
                filtros.participacion 
                ? 'No te encuentras participando en ningún proceso actualmente'
                : 'No se encontraron procesos abiertos para participación'
              }}
            </v-subheader>
          </template>
          <template v-else>
            <template v-if="modalidadEscmConvocatorias">
              <CardConvocatoria 
                v-for="convocatoria in convocatorias.data" 
                :key="convocatoria.id" 
                :convocatoria="convocatoria" 
                @on-visualizar-adjunto="visualizarAdjuntoConvocatoria"
                @on-confirmar-participacion="abrirConfirmacionParticipacion"
                @on-subir-oferta="abrirModalEdicionAdjunto"
                @on-visualizar-adjunto-participacion="visualizarAdjuntoParticipacion"
              />
            </template>
            <template v-else>
              <CardProcesoProveedor v-for="proceso in listaInstituciones" :key="proceso.id" :proceso="proceso" />
            </template>
          </template>
  
          <br />
          <div v-if="loadingMore" class="d-flex justify-center">
            <v-col cols="12">
              <v-skeleton-loader
                  type="list-item-avatar-three-line"
              ></v-skeleton-loader>
            </v-col>
          </div>
  
  
          <div class="d-flex justify-center">
            <v-btn
                v-if="!modalidadEscmConvocatorias && listaInstituciones.length > 0 && total_rows_pac  > listaInstituciones.length"
                :disabled="loading || loadingMore"
                @click="loadMoreProcesos()"
                color="secondary"
                outlined
            >
              Ver más procesos
            </v-btn>
            <v-btn
              v-if="modalidadEscmConvocatorias && (convocatorias.pagination.page * convocatorias.pagination.per_page < convocatorias.pagination.total_rows)"
              :disabled="convocatorias.isLoading || cargandoMasConvocatorias"
              @click.stop="cargarMasConvocatorias"
              color="secondary"
              outlined
            >
              Ver más convocatorias
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </section>
  
    <section class="mt-16" v-else-if="perfilCompleto === false && this.tieneSancion">
      <v-row justify="center" class="mb-8">
        <v-col cols="12" sm="6" md="4" align="center">
          <v-img src="../../assets/img/comprasal_login.png" />
        </v-col>
      </v-row>
      <h3 class="text-center">
        No puede visualizar los procesos vigentes al no tener perfil completo y al poseer sanciones
      </h3>
    </section>
  
    <section class="mt-16" v-else-if="perfilCompleto === false">
      <v-row justify="center" class="mb-8">
        <v-col cols="12" sm="6" md="4" align="center">
          <v-img src="../../assets/img/comprasal_login.png" />
        </v-col>
      </v-row>
      <h3 class="text-center">
        Debe completar su perfil para observar los procesos vigentes
      </h3>
    </section>
  
    <section class="mt-16" v-else-if="this.tieneSancion === true">
      <v-row justify="center" class="mb-8">
        <v-col cols="12" sm="6" md="4" align="center">
          <v-img src="../../assets/img/comprasal_login.png" />
        </v-col>
      </v-row>
      <h3 class="text-center">
        No puedes participar por el momento, debido a que tienes sanciones vigentes.
      </h3>
    </section>

    <!-- Visualizacion archivo convocatoria -->
    <PdfModal
      :isOpen="modalVisualizacionAdjuntoConvocatoriaAbierta" 
      :source-loadable="visualizacionAdjuntoConvocatoria" 
      @on-visibility-change="manejarVisibilidadModalVisualizacionAdjuntoConvocatoria"
      filename="adjunto-convocatoria"
    />

    <!-- Confirmacion participacion -->
    <ConfirmationModalComponent 
      :is-open="confirmacionParticipacionAbierta"
      description="¿Desea participar en esta convocatoria?"
      :isLoading="participacion.isLoading"
      @confirm="participarEnConvocatoria"
      @cancel="confirmacionParticipacionAbierta = false"
    />

    <!-- modificacion adjuntos -->
    <AdjuntoConvocatoria 
      :is-open="modalEdicionAdjuntoAbierta"
      @on-save="confirmarEdicionAdjunto"
      @on-visibility-change="manejarVisibilidadEditarOferta"
    />

    <ConfirmationModalComponent 
      :is-open="confirmacionEdicionAdjuntoAbierta"
      description="¿Desea actualizar el adjunto?"
      :is-loading="edicionAdjunto.isLoading"
      @confirm="editarAdjunto"
      @cancel="confirmacionEdicionAdjuntoAbierta = false"
    />

    <!-- Visualizacion archivos -->
    <PdfModal
      :isOpen="modalVisualizacionAdjuntoAbierta" 
      :source-loadable="visualizacionAdjunto" 
      @on-visibility-change="manejarVisibilidadModalVisualizacionAdjunto"
      filename="adjunto-participacion"
    />
  </Fragment>
</template>
<script>
import { createPageable, createLoadable, togglePageable, toggleLoadable, setPageableResponse, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import { ConfirmationModalComponent } from '@/components/utils';
import CardProcesoProveedor from './components/CardProcesoProveedor.vue';
import CardConvocatoria from './components/CardConvocatoria.vue';
import AdjuntoConvocatoria from '../CmConvocatorias/components/AdjuntoConvocatoria.vue';
import { mapActions, mapState, mapMutations } from "vuex";
import PdfModal from '@/components/PdfModal.vue';
import { Fragment } from 'vue-fragment';

export default {
  name: "paacProveedor",
  components: { CardProcesoProveedor, CardConvocatoria, Fragment, PdfModal, ConfirmationModalComponent, AdjuntoConvocatoria },
  data: () => ({
    timer: null,
    filtros: {
      id_etapa: null,
      id_tipo_institucion: null,
      id_institucion: null,
      participacion: null,
      busqueda: null,
      page: 1,
    },
    meses: [
      { cantidadMes: 1, mes: "Último mes" },
      { cantidadMes: 3, mes: "Últimos 3 meses" },
    ],
    perfilCompleto: false,
    timer: null,
    cargandoProcesos: false,
    loading: false,
    loadingMore: false,
    ctl_institucion_var: [],
    tieneSancion: false,
    // Convocatorias
    convocatorias: createPageable([], 10),
    cargandoMasConvocatorias: false,
    // Visualizacion adjuntos convocatoria
    modalVisualizacionAdjuntoConvocatoriaAbierta: false,
    visualizacionAdjuntoConvocatoria: createLoadable(null),
    // Participacion nueva
    convocatoriaActiva: null,
    confirmacionParticipacionAbierta: false,
    participacion: createLoadable(null),
    // Edicion adjunto
    modalEdicionAdjuntoAbierta: false,
    edicionAdjuntoInfo: null,
    confirmacionEdicionAdjuntoAbierta: false,
    edicionAdjunto: createLoadable(null),
    // Visualizacion oferta
    modalVisualizacionAdjuntoAbierta: false,
    visualizacionAdjunto: createLoadable(null),
  }),

  computed: {
    ...mapState("paacProveedor", [
      "listaInstituciones",
      "tipoEntidades",
      "tipoContratos",
      "obsList",
      "total_rows_pac"
    ]),
    listaTipoContratos() {
      const cmConvocatorias = {
        id: -1,
        codigo: 'CM-CONVOCATORIAS',
        compra_tradicional: true,
        nombre: 'Convocatorias de catálogo electrónico',
      };

      return [cmConvocatorias, ...this.tipoContratos];
    },
    modalidadEscmConvocatorias() {
      return this.filtros.id_modalidad === -1;
    },
    cantidadProcesos() {
      return this.modalidadEscmConvocatorias ? this.convocatorias.pagination.total_rows : Number(this.total_rows_pac);
    },
  },

  methods: {
    ...mapActions("paacProveedor", [
      "getTipoEntidades",
      "getPaacProveedor",
      "getTipoContrato",
      "getObsList",
    ]),
    ...mapMutations("paacProveedor", ["setListaInstituciones", "setTotalRowsPaac"]),
    limpiarFiltros() {
      this.cargarProcesos();
    },
    async cargarProcesos() {
      if (this.modalidadEscmConvocatorias) {
        this.cargarConvocatorias();
      } else {
        this.cargandoProcesos = true;
        try {
          await this.getPaacProveedor(this.filtros);
        } finally {
          this.cargandoProcesos = false;
        }
      }
    },

    filterName() {
      if (this.timer) clearTimeout(this.timer);
      this.loading = true;
      this.timer = setTimeout(async () => {
        this.cargarProcesos();
        this.loading = false;
      }, 700);
    },
    async loadMoreProcesos() {
      this.loadingMore = true;
      this.filtros.page = this.filtros.page + 1;
      await this.cargarProcesos();
      this.loadingMore = false;
    },

    aplicarFiltro(payload, filtro) {
      const filtrosCopia = { ...this.filtros };

      switch (filtro) {
        case "entidad":
          filtrosCopia.id_tipo_institucion = payload;
          break;
        case "contrato":
          filtrosCopia.id_modalidad = payload;
          break;
        case "participacion":
          filtrosCopia.participacion = payload;
          break;
      }

      this.filtros = filtrosCopia;

      this.setTotalRowsPaac(0);
      this.setListaInstituciones([]);
      this.convocatorias.data = [];
      this.convocatorias.pagination = 1;

      this.cargarProcesos();
    },

    async deleteFiltro(filtro) {
      const filtrosCopia = { ...this.filtros };

      switch (filtro) {
        case "entidad":
          filtrosCopia.id_tipo_institucion = null;
          break;
        case "contrato":
          filtrosCopia.id_modalidad = null;
          break;
        case "participacion":
          filtrosCopia.participacion = null;
          break;
      }

      this.filtros = filtrosCopia;

      this.setTotalRowsPaac(0);
      this.setListaInstituciones([]);
      this.convocatorias.data = [];
      this.convocatorias.pagination = 1;

      this.cargarProcesos();
    },

    async getProfilePercentage() {
      let response = await this.services.Proveedores.getProviderPercentage();

      if (response.status == 200) {
        this.perfilCompleto = response?.data.perfil_completo;
        this.tieneSancion = response?.data.tieneSancionHomologada;
      }
    },
    async cargarCatalogoInstituciones() {

      const {data, status} = await this.services.Usuarios.getInstituciones();
      if (status == 200) {
        this.ctl_institucion_var = data;
      }
    },
    // Convocatorias
    async cargarConvocatorias() {
      togglePageable(this.convocatorias);
      const { data, headers } = await this.services.ConvocatoriaServices.cargarConvocatoriasPorParticipacion({ 
        participando: this.filtros.participacion,
        nombre: this.filtros.busqueda,
        pagination: true,
        per_page: 10,
        page: 1,
      });

      setPageableResponse(this.convocatorias, data, headers);
    },
    async cargarMasConvocatorias() {
      this.cargandoMasConvocatorias = true;

      const { data, headers } = await this.services.ConvocatoriaServices.cargarConvocatoriasPorParticipacion({ 
        participando: this.filtros.participacion,
        nombre: this.filtros.busqueda,
        pagination: true,
        per_page: 10,
        page: this.convocatorias.pagination.page + 1,
      });

      this.cargandoMasConvocatorias = false;
      setPageableResponse(this.convocatorias, data, headers, { skipOnSuccess: true });

      if (isResponseSuccesful(data)) {
        this.convocatorias.data = this.convocatorias.data.concat(data.data);
      }
    },
    // Visualizar adjunto
    async visualizarAdjuntoConvocatoria(convocatoria) {
      this.modalVisualizacionAdjuntoConvocatoriaAbierta = true;
      const idConvocatoria = convocatoria.id;
      toggleLoadable(this.visualizacionAdjuntoConvocatoria);
      const { data } = await this.services.ConvocatoriaServices.visualizarAdjuntoConvocatoria(idConvocatoria);
      setLoadableResponse(this.visualizacionAdjuntoConvocatoria, data, { isFile: true });
    },
    manejarVisibilidadModalVisualizacionAdjuntoConvocatoria(visible) {
      this.modalVisualizacionAdjuntoConvocatoriaAbierta = visible;
    },
    // Participacion convocatoria
    abrirConfirmacionParticipacion(convocatoria) {
      this.convocatoriaActiva = convocatoria;
      this.confirmacionParticipacionAbierta = true;
    },
    async participarEnConvocatoria() {
      const idConvocatoria = this.convocatoriaActiva.id;
      togglePageable(this.participacion);
      const { data } = await this.services.ConvocatoriaServices.participarEnConvocatoria(idConvocatoria);
      setLoadableResponse(this.participacion, data, { skipOnSuccess: true, showAlertOnSuccess: true });
      this.confirmacionParticipacionAbierta = false;
      
      if (!isResponseSuccesful(data)) {
          if (data.error.code === 'TIME_OUT_ETAPA_RECEPCION') this.cargarProcesos();
          return;
      }
      
      this.cargarProcesos();
    }, 
    // Edicion adjunto
    abrirModalEdicionAdjunto(convocatoria) {
      this.convocatoriaActiva = {...convocatoria};
      this.modalEdicionAdjuntoAbierta = true;
    },
    confirmarEdicionAdjunto(adjunto) {
      this.edicionAdjuntoInfo = adjunto;
      this.modalEdicionAdjuntoAbierta = false;
      this.confirmacionEdicionAdjuntoAbierta = true;
    },
    async editarAdjunto() {
      const idParticipacion = this.convocatoriaActiva.participacion.id;
      const formData = new FormData();
      formData.append('adjunto', this.edicionAdjuntoInfo);

      toggleLoadable(this.edicionAdjunto)
      const { data } = await this.services.ConvocatoriaServices.editarAdjuntoParticipacion(idParticipacion, formData);
      setLoadableResponse(this.edicionAdjunto, data, { skipOnSuccess: true, showAlertOnSuccess: true });
      this.confirmacionEdicionAdjuntoAbierta = false;
      
      if (!isResponseSuccesful(data)) return;
    },
    manejarVisibilidadEditarOferta(visible) {
      this.modalEdicionAdjuntoAbierta = visible;
    },
    // Visualizacion oferta
    async visualizarAdjuntoParticipacion(convocatoria) {
      this.modalVisualizacionAdjuntoAbierta = true;
      const idParticipacion = convocatoria.participacion.id;
      toggleLoadable(this.visualizacionAdjunto);
      const { data } = await this.services.ConvocatoriaServices.visualizarAdjuntoParticipacion(idParticipacion);
      setLoadableResponse(this.visualizacionAdjunto, data, { isFile: true });
    },
    manejarVisibilidadModalVisualizacionAdjunto(visible) {
      this.modalVisualizacionAdjuntoAbierta = visible;
    },
  },
  watch: {
    async perfilCompleto(val) {
      if (val) {
        await Promise.all([
          this.getPaacProveedor(),
          this.getTipoEntidades(),
          this.getTipoContrato(),
          this.getObsList(),
        ]);
      }
    },
  },

  async created() {
    await this.getProfilePercentage();
    await this.cargarCatalogoInstituciones();
  },
};
</script>
<style lang="scss" scoped>
.filters-text {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.v-list-item--link:before {
  background-color: transparent !important;
}

.v-list-item--active .v-list-item__title {
  font-weight: 700 !important;
  color: var(--v-secondary-base) !important;
}

.v-autocomplete__content.v-menu__content {
  max-width: min-content !important;
}

p {
  font-size: 14px;
}

.bg-color {
  background-color: white;
}

.hover-v-card:hover {
  background-color: #cce0f4 !important;
}
</style>
