<template>
  <section>
    <v-row>
      <v-col cols="12">
        <v-breadcrumbs large :items="breadcrumbsItems"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-autocomplete
          outlined
          label="Unidad solicitante"
          placeholder="Buscar"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <DataTable
          :headers="headers"
          :items="items"
          :show_actions="true"
          :actions="actions"
          @detalleInsumo="detalleInsumo"
        >
        </DataTable>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn color="secondary" class="mt-10" outlined>Volver</v-btn>
        <chat></chat>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapState } from "vuex";
import DataTable from "@/components/DataTableComponent.vue";
import chat from "@/components/ChatComponent.vue";

export default {
  name: "insumoAgregadosObs",
  components: {
    DataTable,
    chat,
  },
  data: () => ({
    breadcrumbsItems: [
      {
        text: "Proceso",
        disable: true,
        href: "#",
      },
      {
        text: "0200-2022-P1232",
        disable: true,
        href: "#",
      },
      {
        text: "14111500 papel bond - Resma",
        disable: true,
        href: "#",
      },
    ],

    headers: [
      {
        text: "Correlativo",
        align: "start",
        sortable: true,
        value: "corelative",
      },
      {
        text: "Unidad Solicitante",
        align: "start",
        sortable: true,
        value: "unidadSolicitante",
      },
      {
        text: "Unidad de medida",
        align: "start",
        sortable: true,
        value: "unidadMedida",
      },
      {
        text: "Cantidad",
        align: "start",
        sortable: true,
        value: "cantidad",
      },
      {
        text: "Total ($)",
        align: "start",
        sortable: true,
        value: "total",
      },
      {
        text: "Precio de mercado",
        align: "start",
        sortable: true,
        value: "precioMercado",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: true,
        value: "actions",
      },
    ],
    items: [
      {
        corelative: "1",
        unidadSolicitante: "Unidad de salud",
        unidadMedida: "Unidad",
        cantidad: "10",
        total: "100",
        precioMercado: "10",
      },
      {
        corelative: "2",
        unidadSolicitante: "Unidad de salud",
        unidadMedida: "Unidad",
        cantidad: "10",
        total: "100",
        precioMercado: "10",
      },
      {
        corelative: "3",
        unidadSolicitante: "Unidad de salud",
        unidadMedida: "Unidad",
        cantidad: "10",
        total: "100",
        precioMercado: "10",
      },
      {
        corelative: "4",
        unidadSolicitante: "Unidad de salud",
        unidadMedida: "Unidad",
        cantidad: "10",
        total: "100",
        precioMercado: "10",
      },
      {
        corelative: "5",
        unidadSolicitante: "Unidad de salud",
        unidadMedida: "Unidad",
        cantidad: "10",
        total: "100",
        precioMercado: "10",
      },
      {
        corelative: "6",
        unidadSolicitante: "Unidad de salud",
        unidadMedida: "Unidad",
        cantidad: "10",
        total: "100",
        precioMercado: "10",
      },
      {
        corelative: "7",
        unidadSolicitante: "Unidad de salud",
        unidadMedida: "Unidad",
        cantidad: "10",
        total: "100",
        precioMercado: "10",
      },
      {
        corelative: "8",
        unidadSolicitante: "Unidad de salud",
        unidadMedida: "Unidad",
        cantidad: "10",
        total: "100",
        precioMercado: "10",
      },
      {
        corelative: "9",
        unidadSolicitante: "Unidad de salud",
        unidadMedida: "Unidad",
        cantidad: "10",
        total: "100",
        precioMercado: "10",
      },
    ],
    actions: [
      {
        tooltip: {
          text: "Detalle insumo",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-eye",
        },
        eventName: "detalleInsumo",
      },
    ],
  }),
  computed: {
    ...mapState("procesoCompra", ["breadcrumbsItemsObs"]),
  },
  methods: {
    detalleInsumo(item) {
      this.$router.push({
        name: "detalle-insumo-paac",
        params: { id_unidad: item.corelative },
      });
    },
  },
};
</script>

<style></style>
